import { History } from 'history';

import {
  STORE_UI,
  STORE_USER,
  STORE_ROUTER,
  STORE_EMPLOYER,
  STORE_ADMIN,
  STORE_PAYROLL,
  STORE_HR,
} from '../appConstants';
import { UIStore, UserStore, RouterStore, EmployerStore, AdminStore, PayrollStore, HrStore } from '../stores';
import EmployerService from '../services/employerService';
import AdminService from '../services/adminService';
import PayrollService from '../services/payrollService';
import HrService from '../services/hrService';

export class RootStore {
  [STORE_UI]: UIStore;
  [STORE_USER]: UserStore;
  [STORE_ROUTER]: RouterStore;
  [STORE_EMPLOYER]: EmployerStore;
  [STORE_ADMIN]: AdminStore;
  [STORE_PAYROLL]: PayrollStore;
  [STORE_HR]: HrStore;

  constructor(history: History) {
    Object.assign(this, {
      [STORE_UI]: new UIStore(this),
      [STORE_USER]: new UserStore(this),
      [STORE_ROUTER]: new RouterStore(history),
      [STORE_EMPLOYER]: new EmployerStore(this, new EmployerService()),
      [STORE_ADMIN]: new AdminStore(this, new AdminService()),
      [STORE_PAYROLL]: new PayrollStore(this, new PayrollService()),
      [STORE_HR]: new HrStore(this, new HrService()),
    });
  }
}
