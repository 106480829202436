import { observable, action } from 'mobx';

import EmployerService from '../services/employerService';
import { EmployerModel, EmployerAdminModel } from '../graphql/models/employer';
import CreateEmployerInput from '../graphql/mutations/inputs/CreateEmployerInput';
import UpdateEmployerInput from '../graphql/mutations/inputs/UpdateEmployerInput';
import CreateEmployerUserInput from '../graphql/mutations/inputs/CreateEmployerUserInput';
import UpdateEmployerUserInput from '../graphql/mutations/inputs/UpdateEmployerUserInput';
import CreateBankAccountInput from '../graphql/mutations/inputs/CreateBankAccountInput';
import UpdateBankAccountInput from '../graphql/mutations/inputs/UpdateBankAccountInput';
import CreatePayrollGroupsInput from '../graphql/mutations/inputs/CreatePayrollGroupsInput';
import UpdatePayrollGroupsInput from '../graphql/mutations/inputs/UpdatePayrollGroupsInput';
import { STORE_UI } from '../appConstants';

import { RootStore } from './RootStore';

export class EmployerStore {
  employerData: EmployerModel = {
    id: '',
    name: '',
    clientId: '',
    employerAdmins: [],
    bankAccounts: [],
    employerUsers: [],
    payrollGroups: [],
    roles: [],
  };

  @observable currentEmployer: EmployerModel = this.employerData;

  @observable currentEmployerAdmin: EmployerAdminModel = {
    id: '',
    firstName: '',
    lastName: '',
    email: '',
  };

  @observable employersList: Array<EmployerModel> = [];
  @observable fetchError = '';
  @observable loadEmployers = false;
  @observable loadEmployerState = false;
  @observable loadEmployerUsers = false;

  constructor(public rootStore: RootStore, public employerService: EmployerService) {}

  @action clearEmployer() {
    this.currentEmployer = this.employerData;
  }

  @action clearErrorMsg() {
    this.fetchError = '';
  }

  @action setLoadEmployerState(isLoad = false) {
    this.loadEmployerState = isLoad;
  }

  @action setEmployer(employer: EmployerModel) {
    this.currentEmployer = employer;
  }

  @action setEmployerAdmin(employerAdmin: EmployerAdminModel) {
    this.currentEmployerAdmin = employerAdmin;
  }

  @action
  async loadEmployersList() {
    this.loadEmployers = true;
    const data = await this.employerService.getEmployersList();
    this.loadEmployers = false;
    this.employersList = (data && data.allEmployers) || [];
  }

  // EMPLOYER -----------------------------------------------------
  @action async createEmployer(employerData: CreateEmployerInput) {
    try {
      await this.employerService.createEmployer(employerData);
      this.fetchError = '';
      this.loadEmployersList();
    } catch (e) {
      this.fetchError = e.errors[0].message;
    }
  }

  @action
  async updateEmployer(employerData: UpdateEmployerInput) {
    try {
      await this.employerService.updateEmployer(employerData);
      this.fetchError = '';
      this.loadEmployersList();
    } catch (e) {
      this.fetchError = e.errors[0].message;
    }
  }

  @action
  async deleteEmployer(employerId: string) {
    try {
      await this.employerService.deleteEmployer(employerId);
      this.rootStore[STORE_UI].setFetchError();
      this.loadEmployersList();
    } catch (e) {
      this.rootStore[STORE_UI].setFetchError(e.errors[0].message);
    }
  }

  @action
  async loadEmployer(employerId: string, requestedData?: string) {
    this.loadEmployerState = true;
    this.currentEmployer = await this.employerService.getEmployer(employerId, requestedData);
    this.loadEmployerState = false;
  }

  @action
  async loadEmployerByPayrollAdminEmail(employerId: string, username: string, requestedData?: string) {
    this.loadEmployerState = true;
    this.currentEmployer = await this.employerService.getEmployerByPayrollAdminEmail(
      employerId,
      username,
      requestedData,
    );
    this.loadEmployerState = false;
  }

  @action async loadEmployerByAdminEmail(adminEmail: string, requestedData?: string) {
    this.loadEmployerState = true;
    this.currentEmployer = await this.employerService.getEmployerByAdminEmail(adminEmail, requestedData);
    this.loadEmployerState = false;
  }

  @action async loadEmployerByHrEmail(hrEmail: string, requestedData?: string) {
    this.loadEmployerState = true;
    this.currentEmployer = await this.employerService.getEmployerByHrEmail(hrEmail, requestedData);
    this.loadEmployerState = false;
  }

  // EMPLOYER USER - employer admin, employer hr, employer payroll ---
  @action async createAdminUser(employerUserData: CreateEmployerUserInput, employerId: string, employerEmail?: string) {
    try {
      await this.employerService.createEmployerUser(employerId, employerUserData);
      this.fetchError = '';
      // eslint-disable-next-line
      employerEmail ? this.loadEmployerByAdminEmail(employerEmail) : this.loadEmployer(employerId);
    } catch (e) {
      this.fetchError = e.errors[0].message;
    }
  }

  @action async updateAdminUser(employerUserData: UpdateEmployerUserInput, employerId: string, employerEmail?: string) {
    try {
      await this.employerService.updateEmployerUser(employerId, employerUserData);
      this.fetchError = '';
      // eslint-disable-next-line
      employerEmail ? this.loadEmployerByAdminEmail(employerEmail) : this.loadEmployer(employerId);
    } catch (e) {
      this.fetchError = e.errors[0].message;
    }
  }

  @action async deleteAdminUser(employerUserId: string, employerId: string, employerEmail?: string) {
    try {
      await this.employerService.deleteEmployerUser(employerId, employerUserId);
      this.rootStore[STORE_UI].setFetchError();
      // eslint-disable-next-line
      employerEmail ? this.loadEmployerByAdminEmail(employerEmail) : this.loadEmployer(employerId);
    } catch (e) {
      this.rootStore[STORE_UI].setFetchError(e.errors[0].message);
    }
  }

  // BANK ACCOUNTS -----------------------------------------------
  @action async createBankAccount(bankAccountData: CreateBankAccountInput, employerId: string) {
    try {
      await this.employerService.createBankAccount(employerId, bankAccountData);
      this.fetchError = '';
      this.loadEmployer(employerId);
    } catch (e) {
      this.fetchError = e.errors[0].message;
    }
  }

  @action async updateBankAccount(bankAccountData: UpdateBankAccountInput, employerId: string) {
    try {
      await this.employerService.updateBankAccount(bankAccountData);
      this.fetchError = '';
      this.loadEmployer(employerId);
    } catch (e) {
      this.fetchError = e.errors[0].message;
    }
  }

  @action async deleteBankAccount(bankAccountId: string, employerId: string) {
    try {
      await this.employerService.deleteBankAccount(bankAccountId);
      this.fetchError = '';
      this.loadEmployer(employerId);
    } catch (e) {
      this.fetchError = e.errors[0].message;
    }
  }

  // PAYROLL GROUPS -----------------------------------------------
  @action async createPayrollGroup(payrollGroupData: CreatePayrollGroupsInput, username: string) {
    try {
      await this.employerService.createPayrollGroup(payrollGroupData);
      this.fetchError = '';
      this.loadEmployerByAdminEmail(username, 'payrollGroups');
    } catch (e) {
      this.fetchError = e.errors[0].message;
    }
  }

  @action async updatePayrollGroup(payrollGroupData: UpdatePayrollGroupsInput, username: string) {
    try {
      await this.employerService.updatePayrollGroup(payrollGroupData);
      this.fetchError = '';
      this.loadEmployerByAdminEmail(username, 'payrollGroups');
    } catch (e) {
      this.fetchError = e.errors[0].message;
    }
  }

  @action async deletePayrollGroup(payrollGroupId: string, username: string) {
    try {
      await this.employerService.deletePayrollGroup(payrollGroupId);
      this.rootStore[STORE_UI].setFetchError();
      this.loadEmployerByAdminEmail(username, 'payrollGroups');
    } catch (e) {
      this.rootStore[STORE_UI].setFetchError(e.errors[0].message);
    }
  }
}
