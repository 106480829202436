import { observable, action } from 'mobx';
import { ChangeEvent } from 'react';

import { InputFileReader } from '../utils/fileReader';
import {
  CSV_UPLOADED_MODAL,
  ERROR_MODAL,
  FORM_MODAL_WIDTH,
  STORE_UI,
  TEST_CSV_MAPPING_MODEL,
  CsvUploadTableShowOptions,
} from '../appConstants';
import { ErrorList } from '../utils/api';
import { EmployerModel, PayrollGroupsModel } from '../graphql/models/employer';
import { PayrollGroupModel, PayrollPaymentSlot } from '../graphql/models/payroll';
import { EmployeeContributionModel } from '../graphql/models/employeeContribution';
import PayrollService from '../services/payrollService';
import { UpdatePayrollGroupPayPeriodInput } from '../graphql/mutations/inputs/UpdatePayrollGroupPayPeriodInput';
import UpdatePayrollIntegrationConfigInput from '../graphql/mutations/inputs/UpdatePayrollIntegrationConfigInput';

import { RootStore } from './RootStore';
import PayrollContributionInput from 'graphql/mutations/inputs/PayrollContributionsInput';

export class PayrollStore {
  @observable payrollCompaniesList: Array<EmployerModel> = [];
  @observable loadPayrollCompanies = false;
  @observable loadPayrollGroup = false;

  @observable payrollGroupsList: Array<PayrollGroupsModel> = [];
  @observable loadPayrollGroups = false;

  @observable payrollGroup: PayrollGroupModel = {
    id: '',
    name: '',
  };

  @observable newContributionsCount = '0';
  @observable newContributionsList: Array<EmployeeContributionModel> = [];
  @observable loadNewContributions = false;
  @observable loadPaymentFileId = '';
  @observable csvErrorCount = 0;
  @observable loadInstructionsPage = false;
  @observable loadInstructions = false;
  @observable fetchError = '';
  @observable loadNewContributionPage = false;

  @observable paymentTabs = [
    { title: 'New contributions (0)', value: 'new-contributions' },
    { title: 'Payments', value: 'payments' },
  ];

  constructor(public rootStore: RootStore, public payrollService: PayrollService) {}

  @action setLoadNewContributionPage(val = false) {
    this.loadNewContributionPage = val;
  }

  @action setLoadIntegrationsPage(isLoading = false) {
    this.loadInstructionsPage = isLoading;
  }

  @action setLoadIntegrations(isLoading = false) {
    this.loadInstructions = isLoading;
  }

  @action clearPayrollGroup() {
    this.payrollGroup = {
      id: '',
      name: '',
    };
  }

  @action loadPayrollGroupTabs() {
    const newContributionsTitle = 'New contributions (' + this.newContributionsCount + ')';
    this.paymentTabs = [
      { title: newContributionsTitle, value: 'new-contributions' },
      { title: 'Payments', value: 'payments' },
    ];
  }

  @action
  async loadPayrollGroupById(groupId: string, requestedData?: string) {
    this.loadPayrollGroup = true;
    this.payrollGroup = await this.payrollService.getPayrollGroupById(groupId, requestedData);
    this.loadPayrollGroup = false;
  }

  @action async loadPayrollCompaniesList(payrollEmail: string) {
    this.loadPayrollCompanies = true;
    this.payrollCompaniesList = await this.payrollService.getPayrollCompaniesList(payrollEmail);
    this.loadPayrollCompanies = false;
  }

  @action loadPayrollGroupsList() {
    this.loadPayrollGroups = true;
    this.payrollGroupsList = [];
  }

  @action async updatePayrollGroupPayPeriod(payrollGroupPayPeriod: UpdatePayrollGroupPayPeriodInput) {
    try {
      await this.payrollService.updatePayrollGroupPayPeriod(payrollGroupPayPeriod);
      this.rootStore[STORE_UI].setFetchError();
    } catch (e) {
      this.rootStore[STORE_UI].setFetchError((e as ErrorList).errors[0].message);
    }
  }

  @action async loadEmployeeNewContributions(payrollGroupId: string) {
    this.loadNewContributions = true;
    this.newContributionsList = await this.payrollService.getEmployeeNewContributions(payrollGroupId);
    this.newContributionsCount = this.newContributionsList.length ? this.newContributionsList.length.toString() : '0';
    this.loadNewContributions = false;
  }

  @action
  async loadEmployeeNewContributionsCount(payrollGroupId: string) {
    this.newContributionsCount = await this.payrollService.getEmployeeNewContributionsCount(payrollGroupId);
    this.newContributionsCount = this.newContributionsCount ? this.newContributionsCount : '0';
    this.loadPayrollGroupTabs();
  }

  @action
  async onChangeFile(event: ChangeEvent<HTMLInputElement>, paymentSlot: PayrollPaymentSlot, groupId: string) {
    if (!event.target.files || !event.target.files.length) {
      return;
    }

    const isCSVfile: boolean | null =
      event.target.files &&
      event.target.files[0] &&
      !!event.target.files[0].name &&
      event.target.files[0].name.indexOf('.csv') !== -1;
    if (!isCSVfile) {
      this.rootStore[STORE_UI].openModal({
        width: FORM_MODAL_WIDTH,
        componentKey: ERROR_MODAL,
        title: 'Error',
        props: {
          description: 'Parsing failed. Please make sure the file has valid CSV content',
        },
      });
      return;
    }

    const fileData = await InputFileReader.getContentFromFileInputEvent(event);
    this.uploadPaymentFile(fileData, paymentSlot, groupId);
  }

  @action
  async uploadPaymentFile(file: any, paymentSlot: PayrollPaymentSlot, groupId: string) {
    const submitPaymentCsv = {
      payrollGroupId: groupId,
      payDateAt: paymentSlot.payDateAt,
      objectKey: paymentSlot.paymentUploadLink.objectKey,
    };

    // eslint-disable-next-line
    paymentSlot.payment ? (this.loadPaymentFileId = paymentSlot.payment.id) : (this.loadPaymentFileId = '-1');

    try {
      await this.payrollService.uploadPaymentFile(file, paymentSlot.paymentUploadLink.uploadUrl);

      const res = await this.payrollService.submitPaymentCsv(submitPaymentCsv);
      this.loadPaymentFileId = '';

      if (res.success) {
        // validation success
        this.rootStore[STORE_UI].closeModal();
        await this.loadPayrollGroupById(groupId);
        this.loadEmployeeNewContributionsCount(groupId);
      } else {
        // validation failed
        this.csvErrorCount = res.resultItems.reduce((accumulator, item) => accumulator + item.errors.length, 0);
        const curriedOnChangeFile = (e: ChangeEvent<HTMLInputElement>) => this.onChangeFile(e, paymentSlot, groupId);

        this.rootStore[STORE_UI].openModal({
          width: FORM_MODAL_WIDTH,
          componentKey: CSV_UPLOADED_MODAL,
          title: 'CSV uploaded',
          props: {
            submitData: res,
            showOption: CsvUploadTableShowOptions.PayrollGroupsPayment,
            csvErrorCount: this.csvErrorCount,
            onChange: curriedOnChangeFile,
          },
        });
      }
    } catch (e) {
      this.loadPaymentFileId = '';
      const errorMsg =
        (e as ErrorList).errors && (e as ErrorList).errors[0] ? (e as ErrorList).errors[0].message : 'Unknown error';
      this.rootStore[STORE_UI].openModal({
        width: FORM_MODAL_WIDTH,
        componentKey: ERROR_MODAL,
        title: 'Error',
        props: {
          description: errorMsg,
        },
      });
    }
  }

  @action
  async deletePaymentFile(groupId: string, paymentFileId: string): Promise<void> {
    try {
      await this.payrollService.deletePaymentFile(paymentFileId);
      await this.loadPayrollGroupById(groupId);
      await this.loadEmployeeNewContributionsCount(groupId);
    } catch (e) {
      this.rootStore[STORE_UI].openModal({
        width: FORM_MODAL_WIDTH,
        componentKey: ERROR_MODAL,
        title: 'Error',
        props: {
          description: e.message,
        },
      });
    }
  }


  @action
  async deletePauseContributions(payrollContributions: PayrollContributionInput): Promise<void> {
    try {
      await this.payrollService.deletePauseContributions(payrollContributions);
    } catch (e) {
      this.rootStore[STORE_UI].openModal({
        width: FORM_MODAL_WIDTH,
        componentKey: ERROR_MODAL,
        title: 'Error',
        props: {
          description: e.message,
        },
      });
    }
  }

  @action
  async updatePayrollManualIntegration(configData: UpdatePayrollIntegrationConfigInput) {
    try {
      this.fetchError = '';
      await this.payrollService.updatePayrollManualIntegration(configData);
      this.loadInstructions = false;
    } catch (e) {
      this.fetchError = (e as ErrorList).errors[0].message;
    }
  }

  @action
  async choosePayrollBatchFile(event: ChangeEvent<HTMLInputElement>, employerId: string) {
    try {
      const fileData = (await InputFileReader.getContentFromFileInputEvent(
        event,
        InputFileReader.defaultMaxFileSize,
      )) as string;
      const submitData = await this.payrollService.validatePayrollSftpIntegrationCsvMapping(fileData, employerId);
      const csvErrorCount = submitData.resultItems.reduce((accumulator, item) => accumulator + item.errors.length, 0);
      this.rootStore[STORE_UI].setFetchError();

      this.rootStore[STORE_UI].openModal({
        width: FORM_MODAL_WIDTH,
        componentKey: CSV_UPLOADED_MODAL,
        title: 'CSV uploaded',
        props: {
          submitData,
          showOption: CsvUploadTableShowOptions.PayrollGroupsPayment,
          csvErrorCount: csvErrorCount,
          onChange: (e: ChangeEvent<HTMLInputElement>) => this.choosePayrollBatchFile(e, employerId),
        },
      });
    } catch (e) {
      this.rootStore[STORE_UI].openModal({
        width: FORM_MODAL_WIDTH,
        componentKey: ERROR_MODAL,
        title: 'Error',
        props: {
          description: e.message,
        },
      });
    }
  }

  // eslint-disable-next-line
  @action async handleMappingTest(employerId: string) {
    const curriedChoosePayrollBatchFile = (e: ChangeEvent<HTMLInputElement>) =>
      this.choosePayrollBatchFile(e, employerId);
    this.rootStore[STORE_UI].openModal({
      width: FORM_MODAL_WIDTH,
      componentKey: TEST_CSV_MAPPING_MODEL,
      title: 'CSV uploaded',
      props: {
        onChange: curriedChoosePayrollBatchFile,
      },
    });
  }
}
