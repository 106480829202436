import React from 'react';
import { Switch, Route, Redirect } from 'react-router';
import { inject, observer } from 'mobx-react';
import {
  STORE_USER,
  STORE_UI,
  AuthRole,
  LOGOUT_TIMEOUT,
  FORM_MODAL_WIDTH,
  ERROR_MODAL,
} from 'appConstants';
import { UserStore, UIStore } from 'stores';
import Modal from 'containers/Modal';
import CommonContainer from './containers/CommonContainer';
import LoginPage from './pages/LoginPage';
import SSOPage from './pages/SSOPage';
import NotFoundPage from './pages/NotFoundPage';
import TermsConditionalsPage from './pages/TermsConditionalsPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import { Hub } from 'aws-amplify';
import IntroPage from './pages/IntroPage';

import UserService from './services/userService';
import IdleTimer from 'react-idle-timer'
import styles from './App.module.scss';
import moment from 'moment';

const PrivateRoute = ({ component, isAuthenticated, role, ...rest }: any) =>
  <Route
    {...rest}
    render={props =>
      isAuthenticated
        ? React.createElement(component, props)
        : <Redirect
          to={{
            pathname: '/login',
            state: { from: props.location }
          }}
        />
    }
  />;

export interface Props {
  [STORE_USER]: UserStore;
  [STORE_UI]: UIStore;
}

const userService = new UserService();

@inject(STORE_USER, STORE_UI)
@observer

class App extends React.Component<Props, {}> {
  idleTimer: IdleTimer | null = null;
  onIdle = this._onIdle.bind(this);

  _onIdle(e: any) {
    this.props[STORE_USER].signOut();
  }

  eventBeforeUnload = () => {
    if (this.idleTimer !== null) {
      localStorage.setItem('closeTime', new Date().toString());
    }
  };

  setupBeforeUnloadListener = () => {
    window.addEventListener('beforeunload', (e) => {
      e.preventDefault();
      return this.eventBeforeUnload();
    });
  };

  async componentDidMount() {
    Hub.listen('auth', data => {
      if (!data) {
        return
      }

      const payload = data.payload

      if (payload.event === 'cognitoHostedUI') {
        localStorage.setItem('closeTime', new Date().toString());
      }

      if (payload.event === 'cognitoHostedUI_failure') {
        const isUserNotFoundError = data.payload.data.message.indexOf('User+not+found') !== -1

        this.props[STORE_UI].openModal({
          width: FORM_MODAL_WIDTH,
          componentKey: ERROR_MODAL,
          title: 'Error',
          props: {
            type: isUserNotFoundError ? 'userNotFound' : 'login'
          },
        });
      }
    });

    await this.props[STORE_USER].appInit();
    let closeTime = localStorage.getItem('closeTime') || '';
    this.setupBeforeUnloadListener();
    let a = moment(new Date());
    let b = moment(new Date(closeTime));

    if (a.diff(b) >= LOGOUT_TIMEOUT) {
      this.props[STORE_USER].signOut();
      localStorage.removeItem('closeTime')
    }

  }

  redirectToHomePage(myRoles: string) {
    const arrayMyRoles = myRoles.split(',');

    if (userService.hasRole(AuthRole.ROLE_PAYCHAIN_ADMIN, arrayMyRoles)) {
      return <Redirect to='/admin/employers' />
    }

    if (userService.hasRole(AuthRole.ROLE_EMPLOYER_ADMIN, arrayMyRoles)) {
      return <Redirect to='/employer/bank-accounts' />
    }

    if (userService.hasRole(AuthRole.ROLE_HR_ADMIN, arrayMyRoles)) {
      return <Redirect to='/hr/new-sign-ups' />
    }

    if (userService.hasRole(AuthRole.ROLE_PAYROLL_ADMIN, arrayMyRoles)) {
      return <Redirect to='/payroll/home' />
    }
  }

  redirectToIntroPage(myRoles: string) {
    const arrayMyRoles = myRoles.split(',');

    if (userService.hasRole(AuthRole.ROLE_PAYCHAIN_ADMIN, arrayMyRoles)) {
      return <Redirect to='/admin/intro' />
    }

    if (userService.hasRole(AuthRole.ROLE_EMPLOYER_ADMIN, arrayMyRoles)) {
      return <Redirect to='/employer/intro' />
    }

    if (userService.hasRole(AuthRole.ROLE_HR_ADMIN, arrayMyRoles)) {
      return <Redirect to='/hr/intro' />
    }

    if (userService.hasRole(AuthRole.ROLE_PAYROLL_ADMIN, arrayMyRoles)) {
      return <Redirect to='/payroll/intro' />
    }
  }

  redirectToAuthenticatedPages(userRoles: string, isFirstLogin: boolean) {
    if (isFirstLogin) {
      return this.redirectToIntroPage(userRoles)
    } else {
      return this.redirectToHomePage(userRoles)
    }
  }

  public render() {
    const { isLoading, isAuthenticated, userRoles } = this.props[STORE_USER];
    let isFirstLogin: boolean = false;
    let keyName = '';

    if (this.props[STORE_USER].user && this.props[STORE_USER].user.username) {
      keyName = 'firstLogin_' + this.props[STORE_USER].user.username;
    }

    if (keyName !== '' && localStorage.getItem(keyName) == null) {
      isFirstLogin = true;
    }

    if (isLoading) {
      return <div> Loading... </div>
    }

    return (
      <div className={styles.appContainer}>
        <Modal uiStore={this.props[STORE_UI]} />
        <IdleTimer
          ref={ref => {
            this.idleTimer = ref
          }}
          element={document}
          onIdle={this.onIdle}
          debounce={250}
          timeout={LOGOUT_TIMEOUT} />

        <Switch>
          <Route exact path='/' render={() => {
            if (isAuthenticated) {
              return this.redirectToAuthenticatedPages(userRoles, isFirstLogin)
            } else {
              return (
                <Redirect to='/login' />
              )
            }
          }
          }
          />

          <Route exact path='/admin/intro' component={IntroPage} />
          <Route exact path='/employer/intro' component={IntroPage} />
          <Route exact path='/hr/intro' component={IntroPage} />
          <Route exact path='/payroll/intro' component={IntroPage} />

          <Route exact path='/login' component={LoginPage} />
          <Route exact path='/sso/saml/unu' component={SSOPage} />
          <Route exact path='/terms-and-conditionals' component={TermsConditionalsPage} />
          <Route exact path='/privacy-policy' component={PrivacyPolicyPage} />

          <PrivateRoute
            isAuthenticated={isAuthenticated}
            component={CommonContainer}
            path={
              [
                '/admin',
                '/employer',
                '/hr',
                '/payroll'
              ]
            }
          />
          <Route component={NotFoundPage} />
        </Switch>
      </div>
    );
  }
}
export default App;
